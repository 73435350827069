var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',{attrs:{"elevation":"0"}},[_c('v-card-title',{staticClass:"px-0 pt-2",staticStyle:{"font-weight":"500"}},[_c('v-icon',{staticClass:"mr-2"},[_vm._v("mdi-calendar-star")]),_vm._v(" 이벤트 ")],1),_c('div',{staticClass:"grid-container"},[(_vm.events && _vm.events.length > 0)?_vm._l((_vm.events),function(item,index){return _c('v-card',{key:index,staticClass:"grid-item",staticStyle:{"cursor":"pointer","position":"relative"},on:{"click":function($event){return _vm.handleClickApplication(item)}}},[_c('div',{staticClass:"backdrop d-flex align-center justify-center"},[_c('v-icon',{attrs:{"large":"","color":"white"}},[_vm._v("mdi-library-outline")])],1),_c('v-card-title',{staticClass:"d-flex flex-column align-start"},[_c('div',{staticClass:"category d-flex align-center justify-space-between"},[_vm._v(" "+_vm._s(_vm._f("formatEventCategory")(item.category))+" "),_c('div',[(item && item.canApply && item.canApply.canApply)?_c('v-chip',{staticClass:"mr-1",attrs:{"small":"","rounded":"","color":item.canApply.canApply ? 'primary' : 'secondary'}},[_vm._v(" "+_vm._s(_vm.formatCanApplyDisplayTitle( item.canApply.canApplyDisplayTitle || '신청' ))+" ")]):_vm._e(),(item && item.canCancel && item.canCancel.canCancel)?_c('v-chip',{attrs:{"small":"","rounded":"","color":_vm.resolveApplicationStatusVariant(
										item.category,
										item.myEventParticipation.status,
										item.myEventParticipation.retake,
										item.amount > 0 ? true : false
									)}},[_vm._v(" "+_vm._s(_vm.resolveApplicationStatus( item.category, item.myEventParticipation.status, item.myEventParticipation.retake, item.amount > 0 ? true : false ))+" ")]):_vm._e(),(
									item &&
									item.canCancel &&
									item.canApply &&
									!item.canApply.canApply &&
									!item.canCancel.canCancel
								)?_c('v-chip',{attrs:{"small":"","rounded":"","color":"secondary"}},[_vm._v(" "+_vm._s(item.canApply.canApplyDisplayTitle)+" "),(
										item.canApply.canApplyDisplayTitle === '접수 인원 초과'
									)?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
									var on = ref.on;
									var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({attrs:{"small":"","color":"white","dark":""}},'v-icon',attrs,false),on),[_vm._v(" mdi-help-circle ")])]}}],null,true)},[_c('span',[_vm._v("결원 발생시 신청가능합니다")])]):_vm._e()],1):_vm._e()],1)]),_c('div',{staticClass:"title"},[_vm._v(_vm._s(item.title))]),_c('div',{staticClass:"eventAt"},[_c('v-icon',{attrs:{"small":""}},[_vm._v("mdi-calendar-star")]),_vm._v(" "+_vm._s(_vm._f("dateSiFormat")(item.eventAt))+" ")],1),_c('div',{staticClass:"eventLocation"},[_c('v-icon',{attrs:{"small":""}},[_vm._v("mdi-map-marker")]),_vm._v(" "+_vm._s(item.location)+" ")],1),_c('div',{staticClass:"participants"},[_c('v-icon',{staticClass:"mr-2",attrs:{"small":""}},[_vm._v("mdi-account-group")]),(item.canApply.canApplyDisplayTitle === '접수 마감')?[_vm._v(" 마감 ")]:[_c('strong',[_vm._v(_vm._s(item.presentParticipants)+" 명")]),_vm._v(" ("+_vm._s(item.maxParticipants)+"명) ")]],2),_c('div',{staticClass:"dueDate"},[_vm._v(" 신청 마감일 "),_c('strong',[_vm._v(_vm._s(_vm._f("_dateFormat")(item.endRegistrationDate)))])])]),_c('v-card-text',{staticStyle:{"position":"absolute","bottom":"0"}},[_c('v-divider',{staticClass:"my-3"}),_c('span',{staticClass:"amount font-weight-bold"},[_vm._v(" "+_vm._s(_vm._f("commaFormat")(item.amount))+" 원 ")])],1)],1)}):[(_vm.isFetchEvents)?_vm._l((6),function(n,index){return _c('div',{key:'skeleton-loader-' + index},[_c('v-skeleton-loader',{attrs:{"type":"image, article"}})],1)}):[_c('h4',[_vm._v("신청 가능한 이벤트가 없습니다")])]]],2)],1)}
var staticRenderFns = []

export { render, staticRenderFns }