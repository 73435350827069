<template>
	<v-card elevation="0">
		<v-card-title class="px-0 pt-2" style="font-weight: 500">
			<v-icon class="mr-2">mdi-calendar-star</v-icon>
			이벤트
		</v-card-title>
		<div class="grid-container">
			<template v-if="events && events.length > 0">
				<v-card
					class="grid-item"
					v-for="(item, index) in events"
					:key="index"
					style="cursor: pointer; position: relative"
					@click="handleClickApplication(item)"
				>
					<div class="backdrop d-flex align-center justify-center">
						<v-icon large color="white">mdi-library-outline</v-icon>
					</div>
					<v-card-title class="d-flex flex-column align-start">
						<div class="category d-flex align-center justify-space-between">
							{{ item.category | formatEventCategory }}

							<div>
								<v-chip
									small
									rounded
									class="mr-1"
									:color="item.canApply.canApply ? 'primary' : 'secondary'"
									v-if="item && item.canApply && item.canApply.canApply"
								>
									{{
										formatCanApplyDisplayTitle(
											item.canApply.canApplyDisplayTitle || '신청',
										)
									}}
								</v-chip>
								<v-chip
									small
									rounded
									:color="
										resolveApplicationStatusVariant(
											item.category,
											item.myEventParticipation.status,
											item.myEventParticipation.retake,
											item.amount > 0 ? true : false,
										)
									"
									v-if="item && item.canCancel && item.canCancel.canCancel"
								>
									{{
										resolveApplicationStatus(
											item.category,
											item.myEventParticipation.status,
											item.myEventParticipation.retake,
											item.amount > 0 ? true : false,
										)
									}}
								</v-chip>
								<v-chip
									small
									rounded
									color="secondary"
									v-if="
										item &&
										item.canCancel &&
										item.canApply &&
										!item.canApply.canApply &&
										!item.canCancel.canCancel
									"
								>
									{{ item.canApply.canApplyDisplayTitle }}

									<v-tooltip
										bottom
										v-if="
											item.canApply.canApplyDisplayTitle === '접수 인원 초과'
										"
									>
										<template v-slot:activator="{ on, attrs }">
											<v-icon small color="white" dark v-bind="attrs" v-on="on">
												mdi-help-circle
											</v-icon>
										</template>
										<span>결원 발생시 신청가능합니다</span>
									</v-tooltip>
								</v-chip>
							</div>
						</div>
						<div class="title">{{ item.title }}</div>
						<div class="eventAt">
							<v-icon small>mdi-calendar-star</v-icon>
							{{ item.eventAt | dateSiFormat }}
						</div>
						<div class="eventLocation">
							<v-icon small>mdi-map-marker</v-icon>
							{{ item.location }}
						</div>
						<div class="participants">
							<v-icon small class="mr-2">mdi-account-group</v-icon>

							<template
								v-if="item.canApply.canApplyDisplayTitle === '접수 마감'"
							>
								마감
							</template>
							<template v-else>
								<strong>{{ item.presentParticipants }} 명</strong>
								({{ item.maxParticipants }}명)
							</template>
						</div>
						<div class="dueDate">
							신청 마감일
							<strong>{{ item.endRegistrationDate | _dateFormat }}</strong>
						</div>
					</v-card-title>
					<v-card-text style="position: absolute; bottom: 0">
						<v-divider class="my-3" />
						<span class="amount font-weight-bold">
							{{ item.amount | commaFormat }} 원
						</span>
					</v-card-text>
				</v-card>
			</template>
			<template v-else>
				<template v-if="isFetchEvents">
					<div v-for="(n, index) in 6" :key="'skeleton-loader-' + index">
						<v-skeleton-loader type="image, article" />
					</div>
				</template>
				<template v-else>
					<h4>신청 가능한 이벤트가 없습니다</h4>
				</template>
			</template>
		</div>
	</v-card>
</template>

<script>
import { ref, watch } from '@vue/composition-api'

import dayjs from 'dayjs'

import {
	resolveApplicationStatus,
	resolveApplicationStatusVariant,
} from '@core/utils/filter'
import { useRouter } from '@core/utils'
import { eventCategory } from '@/helpers'
import { warningSwal } from '@/plugins/swalMixin'

import EventService from '@/services/EventService'
import MarketLicenseService from '@/services/MarketLicenseService'

const MAX_DISPLAY_DAYS = 3

export default {
	components: {},
	setup(_, { emit }) {
		const { router } = useRouter()
		const meLicenses = ref()
		const events = ref([])
		const isFetchEvents = ref(false)
		const selectedEvent = ref({})
		const licenseRequiredList = ref([16, 17, 20, 21, 22])

		const getMeLicenses = async () => {
			const [data] = await MarketLicenseService.getMeLicenses()
			meLicenses.value = data
		}
		getMeLicenses()

		const getMeEvents = async () => {
			try {
				isFetchEvents.value = true
				const data = await EventService.getMeEvents()
				events.value = data.filter((value, _) => {
					return (
						value.category !== eventCategory.EDUCATION_LICENSE.value &&
						value.category !== eventCategory.LICENSE_TEST.value &&
						dayjs().diff(dayjs(value.eventAt), 'day') < MAX_DISPLAY_DAYS
					)
				})
			} catch (e) {
				console.error(e)
			} finally {
				isFetchEvents.value = false
			}
		}
		getMeEvents()

		const handleClickApplication = async item => {
			if (!meLicenses.value && licenseRequiredList.value.includes(item.id)) {
				warningSwal({
					html: 'IT 마케터만 신청가능한 이벤트입니다',
					allowOutSideClick: true,
				})
				return
			}

			router.push({
				name: 'mypage-application-event',
				params: { eventId: item.id },
			})
		}

		const formatCanApplyDisplayTitle = status => {
			return status === '신청' ? '접수중' : status
		}

		watch(
			() => isFetchEvents.value,
			currentValue => {
				if (currentValue) {
					getMeEvents()
					emit('is-fetch-application-board', true)
				}
			},
		)

		return {
			events,
			selectedEvent,
			isFetchEvents,

			resolveApplicationStatus,
			resolveApplicationStatusVariant,
			handleClickApplication,
			formatCanApplyDisplayTitle,
		}
	},
}
</script>
<style lang="scss" scoped>
.v-data-table,
.v-data-table__wrapper {
	display: block;
	overflow-x: auto;
	white-space: nowrap;
}

.grid-container {
	display: grid;
	gap: 24px;
	grid-template-columns: repeat(3, minmax(0, 1fr));
	grid-auto-rows: 1fr;

	@media screen and (max-width: 1489px) {
		grid-template-columns: repeat(3, minmax(0, 1fr));
	}

	@media screen and (max-width: 949px) {
		grid-template-columns: repeat(2, minmax(0, 1fr));
	}

	@media (max-width: 654px) {
		grid-template-columns: repeat(1, minmax(0, 1fr));
	}

	.grid-item {
		min-height: 387px;

		.backdrop {
			height: 110px;
			background-color: rgb(34, 34, 34);
		}

		.v-card__title {
			font-size: 16px;

			.title {
				line-height: 1.3;
				font-weight: 700;
			}

			.eventAt,
			.eventLocation,
			.participants,
			.dueDate {
				font-size: 15px;
				color: rgb(94, 95, 97);
				line-height: 19.4px;
				margin-bottom: 2px;
			}
			.dueDate {
				margin-top: 5px;
				margin-bottom: 5px;
			}

			.category {
				font-size: 13px;
				margin-bottom: 8px;
				font-weight: 700;
				width: 100%;
			}
		}

		.amount {
			font-size: 16px;
			font-weight: 700;
		}
	}
}
</style>
